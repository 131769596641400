import React from "react"
import { 
  Row, 
  Col, 
  Container 
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Informativa Privacy" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container className="text-center mt-5 my-3">
      <Row>
        <Col>
          <h1>Informativa sulla privacy</h1>
        </Col>
      </Row>
      <Row className="my-3">
        <p className="text-justify">
          <b>Oggetto</b>
          <br /> 
          Informativa ai sensi dell’art. 13 del D. Lgs. 196/2003 e dell’articolo 13 del Regolamento UE n. 2016/679 Ai sensi dell’art. 13 del D. Lgs. 196/2003 (di seguito “Codice Privacy”) e dell’art. 13 del Regolamento UE n. 2016/679 (di seguito “GDPR 2016/679”), recante disposizioni a tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali, desideriamo informarLa che i dati personali da Lei forniti formeranno oggetto di trattamento nel rispetto della normativa sopra richiamata e degli obblighi di riservatezza cui è tenuta Autoriparazioni Pit Stop s.n.c..
          <br />
          <b>Titolare del trattamento</b>
          <br />
          Il Titolare del trattamento è Autoriparazioni Pit Stop s.n.c. nella persona del Presidente e legale rappresentante pro tempore Inselvini Emanuele.
          <br />
          <b>Finalità del trattamento</b>
          <br />
          I dati personali da Lei forniti sono necessari per gli adempimenti previsti per legge, Autoriparazioni Pit Stop s.n.c. tratta i Dati al solo fine di dare corso a richieste di preventivi, di fornire i servizi e/o i beni concordati, di valutare proposte di assunzione e/o di collaborazione e comunque di adempiere ad obblighi di legge. Il conferimento dei Dati è facoltativo, tuttavia, il mancato conferimento dei Dati o l’opposizione per motivi legittimi al trattamento dei Dati già conferiti impedirà la prestazione dei servizi e/o dei beni concordati ovvero la valutazione della candidatura a fini di assunzione e/o collaborazione. 
          <br />
          <b>Modalità di trattamento e conservazione</b>
          <br />
          Il trattamento sarà svolto in forma automatizzata e/o manuale, nel rispetto di quanto previsto dall’art. 32 del GDPR 2016/679 e dall’Allegato B del D.Lgs. 196/2003 (artt. 33-36 del Codice) in materia di misure di sicurezza, ad opera di soggetti appositamente incaricati e in ottemperanza a quanto previsto dagli art. 29 GDPR 2016/ 679. Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei dati, ai sensi dell’art. 5 GDPR 2016/679, previo il Suo consenso libero ed esplicito espresso in calce alla presente informativa, i Suoi dati personali saranno conservati per il periodo di tempo necessario per il conseguimento delle finalità per le quali sono raccolti e trattati.
          <br />
          <b>Ambito di comunicazione e diffusione</b>
          <br />
          Informiamo inoltre che i dati raccolti non saranno mai diffusi e non saranno oggetto di comunicazione senza Suo esplicito consenso, salvo le comunicazioni necessarie che possono comportare il trasferimento di dati ad enti pubblici, a consulenti o ad altri soggetti per l’adempimento degli obblighi di legge.
          <br />
          <b>Trasferimento dei dati personali</b>
          <br />
          I suoi dati non saranno trasferiti né in Stati membri dell’Unione Europea né in Paesi terzi non appartenenti all’Unione Europea.
          <br />
          <b>Categorie particolari di dati personali</b>
          <br />
          Ai sensi degli articoli 26 e 27 del D.Lgs. 196/2003 e degli articoli 9 e 10 del Regolamento UE n. 2016/679, Lei potrebbe conferire, al titolare del trattamento dati qualificabili come “categorie particolari di dati personali” e cioè quei dati che rivelano “l'origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l'appartenenza sindacale, nonché dati genetici, dati biometrici intesi a identificare in modo univoco una persona fisica, dati relativi alla salute o alla vita sessuale o all’orientamento sessuale della persona”. Tali categorie di dati potranno essere trattate solo previo Suo libero ed esplicito consenso, manifestato in forma scritta in calce alla presente informativa.
          Esistenza di un processo decisionale automatizzato, compresa la profilazione:
          Autoriparazioni Pit Stop s.n.c. non adotta alcun processo decisionale automatizzato, compresa la profilazione, di cui all’articolo 22, paragrafi 1 e 4, del Regolamento UE n. 679/2016.
          <br />
          <b>Diritti dell’interessato</b>
          <br />
          In ogni momento, Lei potrà esercitare, ai sensi dell’art. 7 del D.Lgs. 196/2003 e degli articoli dal 15 al 22 del Regolamento UE n. 2016/679, il diritto di:
          <br />a) chiedere la conferma dell’esistenza o meno di propri dati personali;
          <br />b) ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati personali, i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati e, quando possibile, il periodo di conservazione;
          <br />c) ottenere la rettifica e la cancellazione dei dati;
          <br />d) ottenere la limitazione del trattamento;
          <br />e) ottenere la portabilità dei dati, ossia riceverli da un titolare del trattamento, in un formato strutturato, di uso comune e leggibile da dispositivo automatico, e trasmetterli ad un altro titolare del trattamento senza impedimenti;
          <br />f) opporsi al trattamento in qualsiasi momento ed anche nel caso di trattamento per finalità di marketing diretto;
          <br />g) opporsi ad un processo decisionale automatizzato relativo alle persone ﬁsiche, compresa la profilazione.
          <br />h) chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati;
          <br />i) revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca;
          <br />j) proporre reclamo a un’autorità di controllo.
          <br />
          Può esercitare i Suoi diritti con richiesta scritta inviata a Autoriparazioni Pit Stop s.n.c., all'indirizzo postale della sede legale o all'indirizzo mail info@autoriparazionipitstop.it.</p>
      </Row>
    </Container>
  </Layout>
)

export default Privacy
